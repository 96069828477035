import React, { useState, useEffect } from "react";
import {
  Hand,
  ALL_HANDS,
  PAIRS,
  OFFSUIT_HANDS,
  SUITED_HANDS,
  BROADWAYS,
  shortenRange,
  packRange,
  unPackRange,
  calcPercentage,
} from "./cards";
import "./App.css";

function HandCell({ hand, selected, addHand, removeHand, mouseDown }) {
  let className = "hand";

  if (selected) {
    className += " selected";
  } else if (hand.isPair) {
    className += " pair";
  } else if (hand.isSuited) {
    className += " suited";
  } else {
    className += " offsuit";
  }

  const toggleHand = () => {
    if (!selected) {
      addHand(hand);
    } else {
      removeHand(hand);
    }
  };

  const handleMouseOver = () => {
    if (!mouseDown) return;
    toggleHand();
  };

  return (
    <div
      className={className}
      onMouseOver={handleMouseOver}
      // not onClick, because we have to start selecting other hands too when dragging after click
      onMouseDown={toggleHand}
    >
      {hand.toString()}
    </div>
  );
}

function RangeTable({ addHand, removeHand, selectedHands, mouseDown }) {
  const allHands = ALL_HANDS.map((hand) => {
    return (
      <HandCell
        key={hand.toString()}
        hand={hand}
        selected={selectedHands.has(hand.toString())}
        addHand={addHand}
        removeHand={removeHand}
        mouseDown={mouseDown}
      />
    );
  });

  return <div className="range">{allHands}</div>;
}

function ControlButton({ children, className = "", ...props }) {
  return (
    <button className={"control " + className} {...props}>
      {children}
    </button>
  );
}

function SelectGroup({ className = "", title, selectFunc, deselectFunc }) {
  return (
    <div className={className}>
      <ControlButton onClick={selectFunc}>+</ControlButton>
      <span className="mr-5 ml-5">{title}</span>
      <ControlButton onClick={deselectFunc}>-</ControlButton>
    </div>
  );
}

function RangeSelector({ loadedHands, mouseDown }) {
  const [selectedHands, setSelectedHands] = useState(loadedHands);
  const [history, setHistory] = useState([loadedHands]);
  const [currentHistory, setCurrentHistory] = useState(0);
  const historyBackDisabled = currentHistory === 0;
  // The first element will either be empty or the loaded hands from URL
  const historyForwardDisabled = history.length <= 1 || currentHistory === history.length - 1;

  useEffect(() => {
    const changeHistoryOnKeyPress = (event) => {
      if (!event.ctrlKey) return;
      else if (!historyBackDisabled && event.key === "z") {
        historyBack();
      } else if (!historyForwardDisabled && (event.key === "y" || (event.shiftKey && event.key === "Z"))) {
        historyForward();
      }
    };

    document.addEventListener("keydown", changeHistoryOnKeyPress);
    return () => {
      document.removeEventListener("keydown", changeHistoryOnKeyPress);
    };
  }, [history, currentHistory]);

  const loadedRange = [...loadedHands].map((hs) => new Hand(hs));
  const [shortRange, setShortRange] = useState(shortenRange(loadedRange));

  const percentage = calcPercentage([...selectedHands].map((hs) => new Hand(hs)));

  const changeURL = (hands) => {
    const url = new URL(window.location.href);
    const newURLParams = new URLSearchParams(window.location.search);
    newURLParams.set("r", packRange(hands));
    url.search = newURLParams;
    window.history.replaceState(null, "", url);
  };

  const historyBack = () => {
    const prevHistory = Math.max(0, currentHistory - 1);
    setValues(history[prevHistory]);
    setCurrentHistory(prevHistory);
  };

  const historyForward = () => {
    const nextHistory = Math.min(history.length - 1, currentHistory + 1);
    setValues(history[nextHistory]);
    setCurrentHistory(nextHistory);
  };

  const setValues = (hands) => {
    const rangeHands = [...hands].map((hs) => new Hand(hs));
    const shortedRange = shortenRange(rangeHands);
    changeURL(rangeHands);
    setSelectedHands(hands);
    setShortRange(shortedRange);
  };

  const setValuesWithHistory = (hands) => {
    setValues(hands);
    const newHistory = [...history];
    newHistory.splice(currentHistory + 1);
    newHistory.push(hands);
    setHistory(newHistory);
    setCurrentHistory(newHistory.length - 1);
  };

  const addHand = (hand) => {
    const newHands = new Set(selectedHands);
    newHands.add(hand.toString());
    setValuesWithHistory(newHands);
  };

  const removeHand = (hand) => {
    const newHands = new Set(selectedHands);
    newHands.delete(hand.toString());
    setValuesWithHistory(newHands);
  };

  const extendHands = (additionalHands) => () => {
    const additionalHandStrings = additionalHands.map((h) => h.toString());
    const newHands = new Set([...selectedHands, ...additionalHandStrings]);
    setValuesWithHistory(newHands);
  };

  const removeHands = (handsToRemove) => () => {
    const newHands = new Set(selectedHands);
    handsToRemove.forEach((hand) => {
      newHands.delete(hand.toString());
    });
    setValuesWithHistory(newHands);
  };

  return (
    <div className="flex flex-column">
      <div>
        <input value={percentage + "%"} type="text" className="percent text-center" readOnly />
        <input
          value={shortRange}
          className="ml-5 shortrange"
          type="text"
          readOnly
          onFocus={(e) => e.target.select()}
        />
      </div>
      <div className="flex flex-column">
        <RangeTable selectedHands={selectedHands} addHand={addHand} removeHand={removeHand} mouseDown={mouseDown} />
        <div className="selector-grid mt-5">
          <SelectGroup title="Pairs" selectFunc={extendHands(PAIRS)} deselectFunc={removeHands(PAIRS)} />
          <SelectGroup title="Broadways" selectFunc={extendHands(BROADWAYS)} deselectFunc={removeHands(BROADWAYS)} />

          <SelectGroup
            title="Suited"
            selectFunc={extendHands(SUITED_HANDS)}
            deselectFunc={removeHands(SUITED_HANDS)}
          />
          <SelectGroup
            title="Offsuit"
            selectFunc={extendHands(OFFSUIT_HANDS)}
            deselectFunc={removeHands(OFFSUIT_HANDS)}
          />

          <SelectGroup title="All" selectFunc={extendHands(ALL_HANDS)} deselectFunc={() => setValues(new Set())} />
          <div>
            <ControlButton onClick={historyBack} disabled={historyBackDisabled}>
              ⮌
            </ControlButton>
            <span className="mr-5 ml-5">Undo</span>
            <ControlButton onClick={historyForward} disabled={historyForwardDisabled}>
              ⮎
            </ControlButton>
          </div>
        </div>
      </div>
    </div>
  );
}

// track if mouse button is pressed anywhere in the document
function useMouseDown() {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    const mousePressed = () => setMouseDown(true);
    document.addEventListener("mousedown", mousePressed);

    const mouseReleased = () => setMouseDown(false);
    document.addEventListener("mouseup", mouseReleased);
    return () => {
      document.removeEventListener("mousedown", mousePressed);
      document.removeEventListener("mouseup", mouseReleased);
    };
  }, [mouseDown]);

  return mouseDown;
}

function App() {
  const mouseDown = useMouseDown(false);

  const urlParams = new URLSearchParams(window.location.search);
  const rangeParam = urlParams.get("r");
  const loadedHands = rangeParam ? unPackRange(rangeParam) : new Set();

  return (
    <div className="app">
      <RangeSelector loadedHands={loadedHands} mouseDown={mouseDown} />
    </div>
  );
}

export default App;
