import BitArray from "./BitArray.js";

const CARDS = ["A", "K", "Q", "J", "T", "9", "8", "7", "6", "5", "4", "3", "2"];

// prettier-ignore
const CARD_VALUES = {
  A: 14,  K: 13,  Q: 12,  J: 11,  T: 10,
  9: 9,  8: 8,  7: 7,  6: 6,  5: 5,  4: 4,  3: 3,  2: 2,
};

// 13 pairs = 13 * 6 = 78 combo
// 78 offsuit = 78 * 12 = 936 combo
// 78 suited = 78 * 4 = 312 combo
const TOTAL_COMBOS = 1326;

function rankDifference(a, b) {
  return Math.abs(CARD_VALUES[a] - CARD_VALUES[b]);
}

class Hand {
  constructor(handString) {
    this.first = handString[0];
    this.second = handString[1];
    this.suit = handString[2] || "";
  }

  toString() {
    return this.first + this.second + this.suit;
  }

  get value() {
    // There are multiple value system, we
    // just define a random one here to get started
    let value = CARD_VALUES[this.first] + CARD_VALUES[this.second];
    if (this.isSuited) value += 1;
    else if (this.isPair) value += 13;
    return value;
  }

  get isPair() {
    return this.first === this.second;
  }

  get isBroadway() {
    return CARD_VALUES[this.first] >= 10 && CARD_VALUES[this.second] >= 10;
  }

  get isSuited() {
    return this.suit === "s" && !this.isPair;
  }

  get isOffsuit() {
    return this.suit === "o" && !this.isPair;
  }

  get numCombos() {
    if (this.isPair) return 6;
    else if (this.isSuited) return 4;
    else if (this.isOffsuit) return 12;
    else throw Error("Invalid hand");
  }
}

function makeAllHands() {
  const allHands = [];
  let hand;

  for (let i = 0; i < CARDS.length; i++) {
    for (let j = 0; j < CARDS.length; j++) {
      if (i < j) {
        hand = new Hand(CARDS[i] + CARDS[j] + "s");
      } else if (i === j) {
        hand = new Hand(CARDS[i] + CARDS[i]);
      } else {
        hand = new Hand(CARDS[j] + CARDS[i] + "o");
      }
      allHands.push(hand);
    }
  }

  return allHands;
}

function getRangeFormat(first, last) {
  if (first.toString() === last.toString()) {
    return first.toString();
  } else if ((first.isPair && first.first === "A") || rankDifference(first.first, first.second) === 1) {
    return last.toString() + "+";
  } else if (last.second === "2") {
    return first.toString() + "-";
  } else {
    return first.toString() + "-" + last.toString();
  }
}

function reverseHandSorter(a, b) {
  const aFirstVal = CARD_VALUES[a.first];
  const aSecondVal = CARD_VALUES[a.second];
  const bFirstVal = CARD_VALUES[b.first];
  const bSecondVal = CARD_VALUES[b.second];

  if (aFirstVal > bFirstVal) {
    return -1;
  } else if (aFirstVal === bFirstVal) {
    if (aSecondVal === bSecondVal) return 0;
    else if (aSecondVal > bSecondVal) return -1;
    else return 1;
  } else {
    return 1;
  }
}

function shortenHands(hands) {
  const sortedHands = [...hands].sort(reverseHandSorter);

  let first = sortedHands[0];
  let last = first;

  const shortener = (acc, current) => {
    if ((current.isPair || last.first === current.first) && rankDifference(last.second, current.second) === 1) {
      last = current;
    } else {
      acc.push(getRangeFormat(first, last));
      first = current;
      last = current;
    }
    return acc;
  };

  let short = sortedHands.splice(1).reduce(shortener, []);
  if (first && last) short.push(getRangeFormat(first, last));

  return short;
}

function shortenRange(hands) {
  if (hands.length === 0) return "";
  const pairs = hands.filter((h) => h.isPair);
  const suited = hands.filter((h) => h.isSuited);
  const offsuits = hands.filter((h) => h.isOffsuit);
  const allHands = [...shortenHands(pairs), ...shortenHands(suited), ...shortenHands(offsuits)];
  return allHands.join(", ");
}

// Convert a range of hands into a base64 encoded string,
// The bits set are the indexes of the hands in the ALL_HANDS Array.
function packRange(hands) {
  if (hands.length === 0) return "";
  const bitArr = hands.reduce((arr, hand) => {
    const ind = ALL_HANDS.findIndex((curr) => hand.toString() === curr.toString());
    arr.set(ind);
    return arr;
  }, new BitArray(13 * 13));
  return bitArr.toUrlSafeBase64();
}

function unPackRange(urlSafeBase64) {
  const bitArr = BitArray.fromUrlSafeBase64(urlSafeBase64);
  const hands = [...bitArr].reduce((acc, isSet, idx) => {
    if (!isSet) return acc;
    const handString = ALL_HANDS[idx].toString();
    acc.add(handString);
    return acc;
  }, new Set());
  return hands;
}

function calcPercentage(selectedHands) {
  const numSelectedCombos = selectedHands.reduce((total, h) => total + h.numCombos, 0);
  const percent = +((numSelectedCombos / TOTAL_COMBOS) * 100).toFixed(1);
  return percent;
}

const ALL_HANDS = makeAllHands();
const PAIRS = ALL_HANDS.filter((h) => h.isPair);
const OFFSUIT_HANDS = ALL_HANDS.filter((h) => h.isOffsuit);
const SUITED_HANDS = ALL_HANDS.filter((h) => h.isSuited);
const BROADWAYS = ALL_HANDS.filter((h) => h.isBroadway);

export {
  Hand,
  ALL_HANDS,
  PAIRS,
  OFFSUIT_HANDS,
  SUITED_HANDS,
  BROADWAYS,
  shortenRange,
  packRange,
  unPackRange,
  calcPercentage,
};
